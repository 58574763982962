// This file is dynamically generated, Manual changes will not be preserved
@import 'gard-variables';

@include media-breakpoint-up(md) {
    .header-section {
        &.home {
            background-image: url('../assets/main-header-background.jpg');
        }

        &.browse {
            background-image: url('../assets/browse-disease-background.jpg');
        }

        &.about {
            background-image: url('../assets/about-page-background.jpg');
        }

        &.contact {
            background-image: url('../assets/contact-us-page-background.jpg');
        }

        &.privacyPolicy,
        &.vulnerabilityPolicy,
        &.foia,
        &.accessibility,
        &.disclaimer {
            text-align: inherit; // just non-empty
        }
    }
}

@include media-breakpoint-down(md) {
    .header-section {
        &.home {
            background-image: url('../assets/main-header-background-mobile.jpg');
        }

        &.browse {
            background-image: url('../assets/browse-disease-background-mobile.jpg');
        }

        &.about {
            background-image: url('../assets/about-page-background-mobile.jpg');
        }

        &.contact {
            background-image: url('../assets/contact-us-page-background-mobile.jpg');
        }

        &.privacyPolicy,
        &.vulnerabilityPolicy,
        &.foia,
        &.accessibility,
        &.disclaimer {
            text-align: inherit; // just non-empty
        }
    }
}
