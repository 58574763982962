@import 'gard-variables';

/* links */

a {
    text-decoration: none;
}

body a:not(.white-link, .white-link a, .btn a, .btn, button a, .not-styled-link a) {
    color: $gard-green;
    cursor: pointer;

    &:hover {
        color: $blue-heading-color;
        text-decoration-color: $blue-heading-color;
        text-decoration-thickness: 0.125em;
        text-decoration: underline;
        text-underline-offset: 1.5px;
    }
}

.white-link a:not(button a, .not-styled-link a) {
    color: white;
    cursor: pointer;

    &:hover {
        text-decoration-color: white;
        text-decoration-thickness: 0.125em;
        text-decoration: underline;
        text-underline-offset: 1.5px;
    }
}

.no-underline,
.no-underline a {
    border-bottom: none !important;
    text-decoration: none !important;
}

//
//
//
// from _reboot.scss
// stylelint-disable declaration-no-important, selector-no-qualifying-type, property-no-vendor-prefix

// Reboot
//
// Normalization of HTML elements, manually forked from Normalize.css to remove
// styles targeting irrelevant browsers while applying new styles.
//
// Normalize is licensed MIT. https://github.com/necolas/normalize.css

// Document
//
// Change from `box-sizing: content-box` so that `width` is not affected by `padding` or `border`.

*,
*::before,
*::after {
    box-sizing: border-box;
}

// Root
//
// Ability to the value of the root font sizes, affecting the value of `rem`.
// null by default, thus nothing is generated.

:root {
    @if $font-size-root != null {
        @include font-size(var(--#{$prefix}root-font-size));
    }

    @if $enable-smooth-scroll {
        @media (prefers-reduced-motion: no-preference) {
            scroll-behavior: smooth;
        }
    }
}

// Body
//
// 1. Remove the margin in all browsers.
// 2. As a best practice, apply a default `background-color`.
// 3. Prevent adjustments of font size after orientation changes in iOS.
// 4. Change the default tap highlight to be completely transparent in iOS.

// scss-docs-start reboot-body-rules
body {
    margin: 0; // 1
    font-family: var(--#{$prefix}body-font-family);
    @include font-size(var(--#{$prefix}body-font-size));
    font-weight: var(--#{$prefix}body-font-weight);
    line-height: var(--#{$prefix}body-line-height);
    color: var(--#{$prefix}body-color);
    text-align: var(--#{$prefix}body-text-align);
    background-color: var(--#{$prefix}body-bg); // 2
    -webkit-text-size-adjust: 100%; // 3
    -webkit-tap-highlight-color: rgba($black, 0); // 4
}
// scss-docs-end reboot-body-rules

// Content grouping
//
// 1. Reset Firefox's gray color

hr {
    margin: $hr-margin-y 0;
    color: $hr-color; // 1
    border: 0;
    border-top: $hr-border-width solid $hr-border-color;
    opacity: $hr-opacity;
}

// Typography
//
// 1. Remove top margins from headings
//    By default, `<h1>`-`<h6>` all receive top and bottom margins. We nuke the top
//    margin for easier control within type scales as it avoids margin collapsing.

%heading {
    margin-top: 0; // 1
    margin-bottom: $headings-margin-bottom;
    font-family: $headings-font-family;
    font-style: $headings-font-style;
    font-weight: $headings-font-weight;
    line-height: $headings-line-height;
    color: var(--#{$prefix}heading-color);
}

h1 {
    @extend %heading;
    @include font-size($h1-font-size);
}

h2 {
    @extend %heading;
    @include font-size($h2-font-size);
}

h3 {
    @extend %heading;
    @include font-size($h3-font-size);
}

h4 {
    @extend %heading;
    @include font-size($h4-font-size);
}

h5 {
    @extend %heading;
    @include font-size($h5-font-size);
}

h6 {
    @extend %heading;
    @include font-size($h6-font-size);
}

// Reset margins on paragraphs
//
// Similarly, the top margin on `<p>`s get reset. However, we also reset the
// bottom margin to use `rem` units instead of `em`.

p {
    margin-top: 0;
    margin-bottom: $paragraph-margin-bottom;
}

// Abbreviations
//
// 1. Add the correct text decoration in Chrome, Edge, Opera, and Safari.
// 2. Add explicit cursor to indicate changed behavior.
// 3. Prevent the text-decoration to be skipped.

abbr[title] {
    text-decoration: underline dotted; // 1
    cursor: help; // 2
    text-decoration-skip-ink: none; // 3
}

// Address

address {
    margin-bottom: 1rem;
    font-style: normal;
    line-height: inherit;
}

// Lists

ol,
ul {
    padding-left: 2rem;
}

ol,
ul,
dl {
    margin-top: 0;
    margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
    margin-bottom: 0;
}

dt {
    font-weight: $dt-font-weight;
}

// 1. Undo browser default

dd {
    margin-bottom: 0.5rem;
    margin-left: 0; // 1
}

// Blockquote

blockquote {
    margin: 0 0 1rem;
}

// Strong
//
// Add the correct font weight in Chrome, Edge, and Safari

b,
strong {
    font-weight: $font-weight-bolder;
}

// Small
//
// Add the correct font size in all browsers

small {
    @include font-size($small-font-size);
}

// Mark

mark {
    padding: $mark-padding;
    background-color: var(--#{$prefix}highlight-bg);
}

// Sub and Sup
//
// Prevent `sub` and `sup` elements from affecting the line height in
// all browsers.

sub,
sup {
    position: relative;
    @include font-size($sub-sup-font-size);
    line-height: 0;
    vertical-align: baseline;
}

sub {
    bottom: -0.25em;
}
sup {
    top: -0.5em;
}

// Code

pre,
code,
kbd,
samp {
    font-family: $font-family-code;
    @include font-size(1em); // Correct the odd `em` font sizing in all browsers.
}

// 1. Remove browser default top margin
// 2. Reset browser default of `1em` to use `rem`s
// 3. Don't allow content to break outside

pre {
    display: block;
    margin-top: 0; // 1
    margin-bottom: 1rem; // 2
    overflow: auto; // 3
    @include font-size($code-font-size);
    color: $pre-color;

    // Account for some code outputs that place code tags in pre tags
    code {
        @include font-size(inherit);
        color: inherit;
        word-break: normal;
    }
}

code {
    @include font-size($code-font-size);
    color: var(--#{$prefix}code-color);
    word-wrap: break-word;

    // Streamline the style when inside anchors to avoid broken underline and more
    a > & {
        color: inherit;
    }
}

kbd {
    padding: $kbd-padding-y $kbd-padding-x;
    @include font-size($kbd-font-size);
    color: $kbd-color;
    background-color: $kbd-bg;
    @include border-radius($border-radius-sm);

    kbd {
        padding: 0;
        @include font-size(1em);
        font-weight: $nested-kbd-font-weight;
    }
}

// Figures
//
// Apply a consistent margin strategy (matches our type styles).

figure {
    margin: 0 0 1rem;
}

// Images and content

img,
svg {
    vertical-align: middle;
}

// Tables
//
// Prevent double borders

table {
    caption-side: bottom;
    border-collapse: collapse;
}

caption {
    padding-top: $table-cell-padding-y;
    padding-bottom: $table-cell-padding-y;
    color: $table-caption-color;
    text-align: left;
}

// 1. Removes font-weight bold by inheriting
// 2. Matches default `<td>` alignment by inheriting `text-align`.
// 3. Fix alignment for Safari

th {
    font-weight: $table-th-font-weight; // 1
    text-align: inherit; // 2
    text-align: -webkit-match-parent; // 3
}

thead,
tbody,
tfoot,
tr,
td,
th {
    border-color: inherit;
    border-style: solid;
    border-width: 0;
}

// Forms
//
// 1. Allow labels to use `margin` for spacing.

label {
    display: inline-block; // 1
}

// Remove the default `border-radius` that macOS Chrome adds.
// See https://github.com/twbs/bootstrap/issues/24093

button {
    // stylelint-disable-next-line property-disallowed-list
    border-radius: 0;
}

// Explicitly remove focus outline in Chromium when it shouldn't be
// visible (e.g. as result of mouse click or touch tap). It already
// should be doing this automatically, but seems to currently be
// confused and applies its very visible two-tone outline anyway.

button:focus:not(:focus-visible) {
    outline: 0;
}

// 1. Remove the margin in Firefox and Safari

input,
button,
select,
optgroup,
textarea {
    margin: 0; // 1
    font-family: inherit;
    @include font-size(inherit);
    line-height: inherit;
}

// Remove the inheritance of text transform in Firefox
button,
select {
    text-transform: none;
}
// Set the cursor for non-`<button>` buttons
//
// Details at https://github.com/twbs/bootstrap/pull/30562
[role='button'] {
    cursor: pointer;
}

select {
    // Remove the inheritance of word-wrap in Safari.
    // See https://github.com/twbs/bootstrap/issues/24990
    word-wrap: normal;

    // Undo the opacity change from Chrome
    &:disabled {
        opacity: 1;
    }
}

// Remove the dropdown arrow only from text type inputs built with datalists in Chrome.
// See https://stackoverflow.com/a/54997118

[list]:not([type='date']):not([type='datetime-local']):not([type='month']):not([type='week']):not([type='time'])::-webkit-calendar-picker-indicator {
    display: none !important;
}

// 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
//    controls in Android 4.
// 2. Correct the inability to style clickable types in iOS and Safari.
// 3. Opinionated: add "hand" cursor to non-disabled button elements.

button,
[type="button"], // 1
[type="reset"],
[type="submit"] {
    -webkit-appearance: button; // 2

    @if $enable-button-pointers {
        &:not(:disabled) {
            cursor: pointer; // 3
        }
    }
}

// Remove inner border and padding from Firefox, but don't restore the outline like Normalize.

::-moz-focus-inner {
    padding: 0;
    border-style: none;
}

// 1. Textareas should really only resize vertically so they don't break their (horizontal) containers.

textarea {
    resize: vertical; // 1
}

// 1. Browsers set a default `min-width: min-content;` on fieldsets,
//    unlike e.g. `<div>`s, which have `min-width: 0;` by default.
//    So we reset that to ensure fieldsets behave more like a standard block element.
//    See https://github.com/twbs/bootstrap/issues/12359
//    and https://html.spec.whatwg.org/multipage/#the-fieldset-and-legend-elements
// 2. Reset the default outline behavior of fieldsets so they don't affect page layout.

fieldset {
    min-width: 0; // 1
    padding: 0; // 2
    margin: 0; // 2
    border: 0; // 2
}

// 1. By using `float: left`, the legend will behave like a block element.
//    This way the border of a fieldset wraps around the legend if present.
// 2. Fix wrapping bug.
//    See https://github.com/twbs/bootstrap/issues/29712

legend {
    float: left; // 1
    width: 100%;
    padding: 0;
    margin-bottom: $legend-margin-bottom;
    @include font-size($legend-font-size);
    font-weight: $legend-font-weight;
    line-height: inherit;

    + * {
        clear: left; // 2
    }
}

// Fix height of inputs with a type of datetime-local, date, month, week, or time
// See https://github.com/twbs/bootstrap/issues/18842

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
    padding: 0;
}

::-webkit-inner-spin-button {
    height: auto;
}

// 1. Correct the outline style in Safari.
// 2. This overrides the extra rounded corners on search inputs in iOS so that our
//    `.form-control` class can properly style them. Note that this cannot simply
//    be added to `.form-control` as it's not specific enough. For details, see
//    https://github.com/twbs/bootstrap/issues/11586.

[type='search'] {
    outline-offset: -2px; // 1
    -webkit-appearance: textfield; // 2
}

// 1. A few input types should stay LTR
// See https://rtlstyling.com/posts/rtl-styling#form-inputs
// 2. RTL only output
// See https://rtlcss.com/learn/usage-guide/control-directives/#raw

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/

// Remove the inner padding in Chrome and Safari on macOS.

::-webkit-search-decoration {
    -webkit-appearance: none;
}

// Remove padding around color pickers in webkit browsers

::-webkit-color-swatch-wrapper {
    padding: 0;
}

// 1. Inherit font family and line height for file input buttons
// 2. Correct the inability to style clickable types in iOS and Safari.

::file-selector-button {
    font: inherit; // 1
    -webkit-appearance: button; // 2
}

// Correct element displays

output {
    display: inline-block;
}

// Remove border from iframe

iframe {
    border: 0;
}

// Summary
//
// 1. Add the correct display in all browsers

summary {
    display: list-item; // 1
    cursor: pointer;
}

// Progress
//
// Add the correct vertical alignment in Chrome, Firefox, and Opera.

progress {
    vertical-align: baseline;
}

// Hidden attribute
//
// Always hide an element with the `hidden` HTML attribute.

[hidden] {
    display: none !important;
}

//
//
//
// from _reboot.scss

// === LAST: Load Bootstrap comonents
@import 'bootstrap-components';

.fs-8 {
    font-size: 0.75rem !important;
}

.fs-7 {
    font-size: 0.875rem !important;
}

.fs-14 {
    font-size: 14px !important;
}

.fs-18 {
    font-size: 1.125rem !important;
}

.fs-20 {
    font-size: 1.25rem !important;
}

.fs-22 {
    font-size: 1.375rem !important;
}

.fs-0 {
    font-size: 4rem !important;
}

/* font size guide
    12px - fs-8     - 0.75rem
    14px - fs-7     - 0.875rem
    16px - fs-6, h6 - 1rem
    18px - fs-18    - 1.125rem
    20px - fs-5, h5 - 1.25rem
    22px - fs-22    - 1.375rem
    24px - fs-4, h4 - 1.5rem
    26px - fs-3, h3 - 1.625rem
    32px - fs-2, h2 - 2rem
    40px - fs-1, h1 - 2.5rem
*/

@include media-breakpoint-up(md) {
    .fs-md-8 {
        font-size: 0.75rem !important;
    }

    .fs-md-7 {
        font-size: 0.875rem !important;
    }

    .fs-md-6 {
        font-size: 1rem !important;
    }

    .fs-md-18 {
        font-size: 1.125rem !important;
    }

    .fs-md-5 {
        font-size: 1.25rem !important;
    }

    .fs-md-22 {
        font-size: 1.375rem !important;
    }

    .fs-md-4 {
        font-size: 1.5rem !important;
    }

    .fs-md-3 {
        font-size: 1.625rem !important;
    }

    .fs-md-2 {
        font-size: 2rem !important;
    }

    .fs-md-1 {
        font-size: 2.5rem !important;
    }

    .fs-md-0 {
        font-size: 4rem !important;
    }
}

@include media-breakpoint-up(lg) {
    .fs-lg-8 {
        font-size: 0.75rem !important;
    }

    .fs-lg-7 {
        font-size: 0.875rem !important;
    }

    .fs-lg-6 {
        font-size: 1rem !important;
    }

    .fs-lg-18 {
        font-size: 1.125rem !important;
    }

    .fs-lg-5 {
        font-size: 1.25rem !important;
    }

    .fs-lg-22 {
        font-size: 1.375rem !important;
    }

    .fs-lg-4 {
        font-size: 1.5rem !important;
    }

    .fs-lg-3 {
        font-size: 1.625rem !important;
    }

    .fs-lg-2 {
        font-size: 2rem !important;
    }

    .fs-lg-1 {
        font-size: 2.5rem !important;
    }

    .fs-lg-0 {
        font-size: 4rem !important;
    }
}

/* ----------------------- END: Modify Bootstrap theme ----------------------- */

body,
html {
    scroll-behavior: inherit !important;
    overflow-x: hidden;
}

/* font-weight */
.fw-100 {
    font-weight: 100;
}

.fw-200 {
    font-weight: 200;
}

.fw-300 {
    font-weight: 300;
}

.fw-400 {
    font-weight: 400;
}

.fw-500 {
    font-weight: 500;
}

.fw-600 {
    font-weight: 600;
}

.fw-700 {
    font-weight: 700;
}

.fw-800 {
    font-weight: 800;
}

.fw-900 {
    font-weight: 900;
}

// align text in the middle vertically
.gard-align-middle {
    height: 100%;
    display: flex;
    align-items: center;
}

/* Section Header */
.h1 .section-header {
    border-left: 5px solid;
    padding-left: 1rem;
}

/* Header Background Bubbles */
/* Usage:
    add these to your html:
    <div class="background-gradient">
        <div class="circle-top-left"></div>
        <div class="circle-lower-right"></div>
    </div>
/* Customization:
    Use "top" and "left" css attributes with !important
    in component styles to update position for a 
    specific circle
*/

.header-gradient {
    background-image: linear-gradient(116.565deg, rgb(15, 52, 127), rgb(5, 109, 102));
    z-index: -2;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    max-height: 100%;
    overflow-x: hidden;
    overflow-y: hidden;
}

.background-gradient {
    background-image: linear-gradient(116.565deg, rgb(15, 52, 127), rgb(5, 109, 102));
    z-index: -2;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: hidden;
}

.circle-top-left {
    height: 440px;
    width: 440px;
    background: rgba(255, 255, 255, 0.16);
    border-radius: 50%;
    position: absolute;
    top: -220px;
    left: 0px;
    z-index: -1;
}

.circle-small-left {
    height: 124px;
    width: 124px;
    background: rgba(255, 255, 255, 0.16);
    border-radius: 50%;
    position: absolute;
    top: 55px;
    left: 100px;
    z-index: -1;
}

.circle-bottom-left {
    height: 440px;
    width: 440px;
    background: rgba(255, 255, 255, 0.16);
    border-radius: 50%;
    position: absolute;
    top: 225px;
    left: -215px;
    z-index: -1;
}

.circle-bottom-transparent {
    height: 550px;
    width: 550px;
    background: transparent;
    border: 2px solid rgba(255, 255, 255, 0.16);
    border-radius: 50%;
    position: absolute;
    top: 150px;
    left: 150px;
    z-index: -1;
}

.circle-top-transparent {
    height: 847px;
    width: 847px;
    background: transparent;
    border: 2px solid rgba(255, 255, 255, 0.16);
    border-radius: 50%;
    position: absolute;
    top: -625px;
    left: 80px;
    z-index: -1;
}

.circle-top-right-transparent {
    height: 558px;
    width: 558px;
    background: transparent;
    border: 2px solid rgba(255, 255, 255, 0.16);
    border-radius: 50%;
    position: absolute;
    top: -300px;
    right: 5px;
    z-index: -1;
}

.circle-top-left-transparent {
    height: 553px;
    width: 553px;
    background: transparent;
    border: 2px solid rgba(255, 255, 255, 0.16);
    border-radius: 50%;
    position: absolute;
    top: -275px;
    left: -150px;
    z-index: -1;
}

.circle-small-right {
    height: 42px;
    width: 42px;
    background: rgba(255, 255, 255, 0.16);
    border-radius: 50%;
    position: absolute;
    top: 275px;
    right: 340px;
    z-index: -1;
}

.circle-lower-right {
    height: 1700px;
    width: 1700px;
    background: rgba(255, 255, 255, 0.16);
    border-radius: 50%;
    position: absolute;
    top: 200px;
    right: -440px;
    z-index: -1;
}

.circle-mobile-top {
    height: 847px;
    width: 847px;
    background: transparent;
    border: 2px solid rgba(255, 255, 255, 0.16);
    border-radius: 50%;
    position: absolute;
    top: -300px;
    left: -600px;
    z-index: -1;
}

.circle-mobile-bottom {
    height: 1700px;
    width: 1700px;
    background: rgba(255, 255, 255, 0.16);
    border-radius: 50%;
    position: absolute;
    bottom: -1400px;
    left: -1050px;
    z-index: -1;
}

/* Gradient container without pixeled height and responsive circles */
.gradient-container {
    position: relative;
    background-image: linear-gradient(116.565deg, rgb(15, 52, 127), rgb(5, 109, 102));
    overflow: hidden;
    min-height: 100vh;

    * {
        z-index: 2;
    }

    @mixin circle($width) {
        width: $width;
        height: $width;
        right: calc($width / -2);
        z-index: 1;
    }

    .circle-lower-right {
        @include circle(120vw);

        &.small {
            @include circle(30vw);
        }
    }

    .circle-top-left {
        @include circle(30vw);

        &.small {
            @include circle(10vw);
        }
    }
}

/* ------ END of Header Background Bubbles ------- */
li.gard-style {
    list-style-type: none;
    position: relative;

    &::before {
        content: '\2022';
        position: absolute;
        color: $gard-blue-gray;
        top: -0.7rem;
        left: -0.4em;
        font-size: 2em;
    }
}

// collapse state
.inverse-collapse {
    &.collapse {
        display: none;

        &:not(.show) {
            display: block;
        }
    }

    &.collapsing {
        display: none;
    }
}

.cursor-pointer {
    cursor: pointer;
}

.underline {
    text-decoration: underline;
}

.blue-effect {
    &:hover {
        background-color: $blue-heading-color;
        border-color: $blue-heading-color;

        &[class*='text-']:not(.text-white) {
            color: #fff !important;
        }

        &:focus {
            box-shadow: 0 0 0 $btn-focus-width rgba($blue-heading-color, 0.5) !important;
        }
    }
}

.pb-32 {
    padding-bottom: 2rem;
}

.pt-5rem {
    padding-top: 5rem;
}

.text-gard-green {
    color: $gard-green;
}

// for clamper
span.ghost a {
    color: transparent !important;
}

/* tabs */
.top-level-nav {
    button.nav-link {
        background: transparent;
        color: #ffffff;
        white-space: nowrap;
        border-top-left-radius: 0.25em;
        border-top-right-radius: 0.25em;
        font-size: 1.5rem;
        font-weight: normal;
        padding: 0.5em 3em;

        &.active {
            @include tab-shadow;
            background-color: $gard-light-blue !important;
            color: $blue-heading-color;
            font-weight: bolder;
        }
    }

    &.nav {
        flex-wrap: nowrap;
        overflow-x: auto;
        overflow-y: hidden;
    }
}

@include media-breakpoint-down(lg) {
    .top-level-nav {
        padding-left: 40px;
        padding-right: 40px;

        button.nav-link {
            border-top-left-radius: 1em;
            border-top-right-radius: 1em;
            font-size: 1rem;
            font-weight: normal;
            padding: 0.5rem 2rem 0.5rem 2rem;

            &.active {
                background-color: $gard-light-blue !important;
                color: $blue-heading-color;
                font-weight: bolder;
            }
        }
    }
}

// arrow icons inside buttons
.btn {
    &.btn-arrow {
        padding-left: 1.25rem;
        padding-right: 1.25rem;

        i {
            position: relative;
            width: 1rem;
            height: 1rem;
            display: inline-block;
            font-size: 0.875rem;

            &::before {
                // position: absolute;
                left: 0;
            }
        }
    }
}

// 80px padding needed at the top of disease page
// 48px default: 80px-48px=32px or 2rem
.disease-page-top-element-padding {
    padding-top: 2rem;
}

.position-inherit {
    position: inherit;
}

.align-left {
    text-align: left;
}

.align-right {
    text-align: right;
}

.align-center {
    text-align: center;
}

.width-fit-content {
    width: -moz-fit-content;
    width: fit-content;
}

.scroll-area {
    overflow-y: auto;
    overflow-x: hidden;
    /* Scrollbar */

    &::-webkit-scrollbar {
        width: 0.5rem;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: transparent;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: #d7dde2 0% 0% no-repeat padding-box;
        width: 0.5rem;
        border-radius: 0.3125rem;
    }
}
.rounded-8 {
    border-radius: 8px;
}

app-tooltip {
    display: inline;
}
