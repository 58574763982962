// default root vars must be overwritten before importing bootstrap/variables
$enable-rfs: false;

// Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import 'functions';

// === Variables needed for core Bootstrap: theme, colors, other mixin variables ===

// legacy naming
$blue-heading-color: #003494;
$maroon: #7f2754;

$gard-maroon: $maroon;
$gard-blue: $blue-heading-color;
$gard-green: #007564;
$gard-teal: #006478;
$gard-light-blue: #f7fbfd;
$gard-dark-blue: #00256a;
$gard-gray: #707070;
$gard-light-gray: #b5bfc6;
$gard-blue-gray: #58676e;
$gard-gainsboro: #dae1e6;
$gard-greyish: #e4e8eb;
$gard-pink: #bd8fa6;
$gard-yellow: #fee208;
$gard-dark: #454f54;
$white: white;
$gard-nav-bar: #f7fbfd;

$transparent-white-background-color: rgba(255, 255, 255, 0.2);
$shadow-color: rgba(0, 0, 0, 0.05);

@mixin default-box-shadow {
    box-shadow: 0px 4px 4px $shadow-color;
}

@mixin tab-shadow {
    box-shadow: 0px -4px 4px $shadow-color;
}

@mixin rotate-element($angle) {
    -moz-transform: rotate($angle);
    -ms-transform: rotate($angle);
    -webkit-transform: rotate($angle);
    transform: rotate($angle);
}

$theme-colors: (
    // extend bootstrap theme colors
    'dark': $gard-dark,
    'blue': $blue-heading-color,
    'maroon': $maroon,
    'purple': #662e6b,
    'green': $gard-green,
    'teal': $gard-teal,
    'lightblue': $gard-light-blue,
    'lightgray': $gard-light-gray,
    'greyish': $gard-greyish,
    'white': $white,
    'bluegray': $gard-blue-gray,
    'darkblue': $gard-dark-blue,
    'gray': $gard-gray,
    'yellow': $gard-yellow
);

// for media container and breakpoints
$gard-native-desktop-screen-size: 1600px;

$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1400px,
    xxxl: $gard-native-desktop-screen-size,
);

/* utilities */
$enable-negative-margins: true;

// scss-docs-start import-stack
// Configuration
// Include remainder of required Bootstrap stylesheets
@import 'variables';
@import 'variables-dark';
@import 'maps';
@import 'mixins';
@import 'utilities';

.container-xxxl {
    @include make-container();
    max-width: $gard-native-desktop-screen-size !important;
    position: relative;
}

/* fonts */
$font-size-base: 1rem;
$h3-font-size: $font-size-base * 1.625 !default; // all other sizes match the mockup except this one

// === Bootstrap variables are declared HERE ===

/* buttons */
$border-radius: 1.5rem;
$border-radius-sm: 1.5rem;
$btn-border-radius: 1.5rem;
$btn-border-radius-sm: 1.5rem;
$btn-font-size: 1.125rem;
// $btn-padding-x: 1.25rem;

/* dropdown */
$dropdown-border-radius: 0.5rem;
$dropdown-border-radius-sm: 0.5rem;

/* links */
$link-decoration: none;

// Body and Font default
$font-family-base: 'Roboto', sans-serif;
$body-color: $gard-blue-gray;

/* tootips */
$tooltip-opacity: 1;
$tooltip-color: $gard-blue-gray;
$tooltip-bg: $white;
$tooltip-arrow-color: $white;
$tooltip-padding-y: $spacer * 1.5;
$tooltip-padding-x: $spacer * 1.5;
$tooltip-font-size: 1.125rem;

// Navbar
$navbar-dark-active-color: white;
:root {
    --bs-navbar-active-color: white;
}
