@font-face {
    font-family: 'grd';
    src: url('../font/grd.eot?62460666');
    src: url('../font/grd.eot?62460666#iefix') format('embedded-opentype'), url('../font/grd.woff2?62460666') format('woff2'), url('../font/grd.woff?62460666') format('woff'),
        url('../font/grd.ttf?62460666') format('truetype'), url('../font/grd.svg?62460666#grd') format('svg');
    font-weight: normal;
    font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'grd';
    src: url('../font/grd.svg?62460666#grd') format('svg');
  }
}
*/
[class^='icon-']:before,
[class*=' icon-']:before {
    font-family: 'grd';
    font-style: normal;
    font-weight: normal;
    speak: never;

    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: 0.2em;
    text-align: center;
    /* opacity: .8; */

    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;

    /* fix buttons height, for twitter bootstrap */
    /* line-height: 1em; */

    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */
    margin-left: 0.2em;

    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */

    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-gard-adolescent:before {
    content: '\e800';
} /* '' */
.icon-gard-adult:before {
    content: '\e801';
} /* '' */
.icon-gard-antenatal:before {
    content: '\e802';
} /* '' */
.icon-gard-arrow-down:before {
    content: '\e803';
} /* '' */
.icon-gard-arrow-left:before {
    content: '\e804';
} /* '' */
.icon-gard-arrow-right:before {
    content: '\e805';
} /* '' */
.icon-gard-arrow-up:before {
    content: '\e806';
} /* '' */
.icon-gard-back-fill:before {
    content: '\e807';
} /* '' */
.icon-gard-back:before {
    content: '\e808';
} /* '' */
.icon-gard-care-team:before {
    content: '\e809';
} /* '' */
.icon-gard-childhood:before {
    content: '\e80a';
} /* '' */
.icon-gard-close:before {
    content: '\e80b';
} /* '' */
.icon-gard-connect:before {
    content: '\e80c';
} /* '' */
.icon-gard-doctor:before {
    content: '\e80d';
} /* '' */
.icon-gard-download:before {
    content: '\e80e';
} /* '' */
.icon-gard-drop-arrow-up:before {
    content: '\e80f';
} /* '' */
.icon-gard-elderly:before {
    content: '\e810';
} /* '' */
.icon-gard-expand:before {
    content: '\e811';
} /* '' */
.icon-gard-family:before {
    content: '\e812';
} /* '' */
.icon-gard-health-care:before {
    content: '\e814';
} /* '' */
.icon-gard-heart-check:before {
    content: '\e815';
} /* '' */
.icon-gard-heart:before {
    content: '\e816';
} /* '' */
.icon-gard-infancy:before {
    content: '\e817';
} /* '' */
.icon-gard-info:before {
    content: '\e818';
} /* '' */
.icon-gard-key:before {
    content: '\e819';
} /* '' */
.icon-gard-list-view:before {
    content: '\e81a';
} /* '' */
.icon-gard-list:before {
    content: '\e81b';
} /* '' */
.icon-gard-medical-history:before {
    content: '\e81c';
} /* '' */
.icon-gard-newborn:before {
    content: '\e81d';
} /* '' */
.icon-gard-drop-arrow-down:before {
    content: '\e81e';
} /* '' */
.icon-gard-nih-small:before {
    content: '\e81f';
} /* '' */
.icon-gard-financial:before {
    content: '\e820';
} /* '' */
.icon-gard-nih:before {
    content: '\e821';
} /* '' */
.icon-gard-open-link:before {
    content: '\e822';
} /* '' */
.icon-gard-person:before {
    content: '\e823';
} /* '' */
.icon-gard-plan:before {
    content: '\e824';
} /* '' */
.icon-gard-questions:before {
    content: '\e825';
} /* '' */
.icon-gard-research:before {
    content: '\e826';
} /* '' */
.icon-gard-resource:before {
    content: '\e827';
} /* '' */
.icon-gard-sort-down:before {
    content: '\e828';
} /* '' */
.icon-gard-contact:before {
    content: '\e829';
} /* '' */
.icon-gard-specialist:before {
    content: '\e82b';
} /* '' */
.icon-gard-information:before {
    content: '\e82c';
} /* '' */
.icon-gard-tile-view:before {
    content: '\e82d';
} /* '' */
.icon-gard-treatments:before {
    content: '\e82e';
} /* '' */
.icon-gard-usa:before {
    content: '\e82f';
} /* '' */
.icon-gard-search:before {
    content: '\e830';
} /* '' */
.icon-gard-world:before {
    content: '\e831';
} /* '' */
.icon-gard-menu:before {
    content: '\e832';
} /* '' */
.icon-gard-check:before {
    content: '\e833';
} /* '' */
.icon-gard-sort-up:before {
    content: '\e834';
} /* '' */
.icon-gard-phone:before {
    content: '\e835';
} /* '' */
.icon-gard-sort:before {
    content: '\e836';
} /* '' */
.icon-gard-filter:before {
    content: '\e837';
} /* '' */
.icon-gard-plus:before {
    content: '\e839';
} /* '' */
.icon-gard-minus:before {
    content: '\e83b';
} /* '' */
